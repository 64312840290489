import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import logo from "./logo.svg";
import "./App.css";
import LogIn from "./screens/login/LogIn";
import User from "./screens/user/User";

function App() {
  return (
      <Router>
        <Routes>
          <Route path="/" element={<LogIn />} />
          <Route path="/user" element={<User />} />
        </Routes>
      </Router>
  );
}

export default App;
