import { configureStore, combineReducers } from '@reduxjs/toolkit';
import thunkMiddleware from 'redux-thunk';
import { InitialState } from './payloadTypes';

import * as revisions from './revisions';

const appReducer = combineReducers({
  [revisions.name]: revisions.reducer,
});

const reducer = (state:any, action:any) => {
  let nextState = state;
  return appReducer(nextState, action);
};

export const store = configureStore({
  reducer: reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      thunkMiddleware
    ),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
 export type RootState = ReturnType<typeof store.getState>
// // Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
 export type AppDispatch = typeof store.dispatch