import { endAt } from 'firebase/firestore';
import {
  getFunctions,
  httpsCallable,
  HttpsCallableResult,
} from 'firebase/functions';

// revision: {
//     tip: 'test type',
//     operatorName: 'Test Op',
//     trainNumber: 1234,
//     station: 'statie test',
//     date: new Date(),
//     other: '',
//   },

export const addRevision = (uid, idToken, revisor, revision) => {
  const addRevision = httpsCallable(getFunctions(), 'addRevision');
  addRevision({
    idToken: idToken,
    uid: uid,
    revisor: revisor,
    revision: revision,
  })
    .then((res) => {
      console.log(res);
    })
    .catch((err) => {
      console.log('err', err);
    });
};

export const getRevisions = async (uid, idToken) => {
  const getRevisions = httpsCallable(getFunctions(), 'getRevisions');
  const rev = await getRevisions({ idToken: idToken, uid: uid });
  return rev.data;
};

export const getPaginatedRevisions = async (uid, idToken, startAt, endAt) => {
  const getPaginatedRevisions = httpsCallable(
    getFunctions(),
    'getPaginatedRevisions'
  );
  const revisions = await getPaginatedRevisions({
    uid: uid,
    idToken: idToken,
    startAt: startAt,
    endAt: endAt,
  });
  return revisions.data;
};

export const getRevisionsCount = async (uid, idToken) => {
  const getRevisionsCount = httpsCallable(getFunctions(), 'getRevisionsCount');
  const count = await getRevisionsCount({ idToken: idToken, uid: uid });
  return count.data;
};
