import {
  createAsyncThunk,
  createSlice,
  createSelector,
  PayloadAction,
} from '@reduxjs/toolkit';

//import { AppDispatch } from '../index';
import {
  addRevision,
  getRevisions,
  getPaginatedRevisions,
  getRevisionsCount,
} from '../../utils/revisions';
import {
  AddRevisionParams,
  GetRevisionsParams,
  GetPaginatedRevisionsParams,
} from '../payloadTypes';

const sliceName = 'revisions';

const sliceInitialState = {
  revisions: [],
  totalNumberOfRevisions: 0,
  loading: false,
};

const sliceSelector = (state: any) => state[sliceName];

export const selectors = {
  revisions: createSelector(sliceSelector, (state) => state.revisions),
  totalNumberOfRevisions: createSelector(
    sliceSelector,
    (state) => state.totalNumberOfRevisions
  ),
  loading: createSelector(sliceSelector, (state) => state.loading),
};

export const thunks: any = {
  addRevision: createAsyncThunk(
    `${sliceName}/addRevision`,
    ({ uid, idToken, revisor, revision }: AddRevisionParams, { dispatch }) => {
      addRevision(uid, idToken, revisor, revision);
    }
  ),
  getRevisions: createAsyncThunk<any, any, any>(
    `${sliceName}/getRevisions`,
    ({ uid, idToken }: GetRevisionsParams, { dispatch }) => {
      try {
        return getRevisions(uid, idToken);
      } catch (err) {
        console.log('err', err);
        return [];
      }
    }
  ),
  getPaginatedRevisions: createAsyncThunk<any, any, any>(
    `${sliceName}/getPaginatedRevisions`,
    ({ uid, idToken, startAt, endAt }: GetPaginatedRevisionsParams, {}) => {
      try {
        return getPaginatedRevisions(uid, idToken, startAt, endAt);
      } catch (err) {
        console.log('err', err);
        return [];
      }
    }
  ),
  getRevisionsCount: createAsyncThunk<any, any, any>(
    `${sliceName}/getRevisionsCount`,
    ({ uid, idToken }: GetRevisionsParams, {}) => {
      try {
        return getRevisionsCount(uid, idToken);
      } catch (err) {
        console.log('err', err);
        return [];
      }
    }
  ),
};

export const { name, reducer, actions } = createSlice({
  name: sliceName,
  initialState: sliceInitialState,
  reducers: {},
  extraReducers: {
    [thunks.getRevisions.fulfilled]: (state: any, action: any) => {
      state.loading = false;
      state.revisions = action.payload;
    },
    [thunks.getRevisions.pending]: (state: any) => {
      state.loading = true;
    },
    [thunks.getRevisions.error]: (state: any) => {
      state.loading = false;
    },
    [thunks.getPaginatedRevisions.fulfilled]: (state: any, action: any) => {
      state.revisions = action.payload;
      state.loading = false;
    },
    [thunks.getPaginatedRevisions.pending]: (state: any) => {
      state.loading = true;
    },
    [thunks.getPaginatedRevisions.error]: (state: any) => {
      state.laoding = false;
    },
    [thunks.getRevisionsCount.fulfilled]: (state: any, action: any) => {
      state.totalNumberOfRevisions = action.payload;
    },
  },
});
