import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { getFunctions, httpsCallable } from 'firebase/functions';
import Table from 'react-bootstrap/Table';
import { usePagination } from 'react-use-pagination';
import Pagination from 'react-bootstrap/Pagination';
import Spinner from 'react-bootstrap/Spinner';
import { useDispatch, useSelector } from 'react-redux';

import './User.css';
import { auth, logout } from '../../firebase';
import Loading from '../loading/Loading';
import * as revisionsRedux from '../../redux/revisions';
import AddRevisionModal from '../../components/addRevisionModal/AddRevisionModal';

const User = () => {
  const dispatch = useDispatch();

  const [operator, setOperator] = useState<any>('');
  const [trainNumber, setTrainNumber] = useState<any>('');
  const [startDate, setStartDate] = useState<any>(new Date());
  const [station, setStation] = useState<any>('');
  const [revision, setRevisionType] = useState<any>('RTS');
  const [other, setOther] = useState<any>('');
  const [userCheck, setUserCheck] = useState<boolean>(false);
  const [token, setToken] = useState<string>('');
  const [showAddRevisionsModal, setShowAddRevisionsModal] =
    useState<boolean>(false);

  const revisions = useSelector(revisionsRedux.selectors.revisions);
  const totalNumberOfRevisions = useSelector(
    revisionsRedux.selectors.totalNumberOfRevisions
  );
  const loadingRevisions = useSelector(revisionsRedux.selectors.loading);

  const navigate = useNavigate();
  const [user, loading, error] = useAuthState(auth);

  const {
    currentPage,
    totalPages,
    setPage,
    setNextPage,
    setPreviousPage,
    nextEnabled,
    previousEnabled,
    startIndex,
    endIndex,
  } = usePagination({
    totalItems: totalNumberOfRevisions,
    initialPageSize: 10,
  });

  useEffect(() => {
    handleGetRevisions();
  }, []);

  useEffect(() => {
    if (!user) {
      navigate('/');
    }
  }, [user, loading]);

  useEffect(() => {
    user?.getIdToken(true).then((res) => {
      setToken(res);
    });
    dispatch(
      revisionsRedux.thunks.getPaginatedRevisions({
        uid: user?.uid,
        idToken: token,
        startAt: startIndex,
        endAt: endIndex,
      })
    );
  }, [totalPages, startIndex, endIndex]);

  const handleCreateButton = useCallback(() => {
    setShowAddRevisionsModal((prevState) => !prevState);
  }, [showAddRevisionsModal]);

  const handleGetRevisions = async () => {
    const token = await user?.getIdToken(true);
    dispatch(
      revisionsRedux.thunks.getRevisionsCount({
        uid: user?.uid,
        idToken: token,
      })
    );
    dispatch(
      revisionsRedux.thunks.getPaginatedRevisions({
        uid: user?.uid,
        idToken: token,
        startAt: startIndex,
        endAt: endIndex,
      })
    );
  };

  const onHideAddRevisionModal = useCallback(() => {
    setOperator('');
    setTrainNumber('');
    setStartDate(new Date());
    setStation('');
    setRevisionType('RTS');
    setOther('');
    setUserCheck(false);
    setShowAddRevisionsModal(false);
  }, [
    operator,
    trainNumber,
    startDate,
    station,
    revision,
    other,
    userCheck,
    setShowAddRevisionsModal,
  ]);

  const handleAddRevision = useCallback(() => {
    dispatch(
      revisionsRedux.thunks.addRevision({
        uid: user?.uid,
        idToken: token,
        revisor: user?.email,
        revision: {
          date: startDate,
          operatorName: operator,
          other: other,
          station: station,
          tip: revision,
          trainNumber: trainNumber,
        },
      })
    );
    onHideAddRevisionModal();
    handleGetRevisions();
  }, [
    user,
    token,
    operator,
    trainNumber,
    startDate,
    station,
    revision,
    other,
    userCheck,
    onHideAddRevisionModal,
    handleGetRevisions,
  ]);

  if (loading) return <Loading />;

  return (
    <>
      <Navbar
        collapseOnSelect
        expand="lg"
        bg="dark"
        variant="dark"
        sticky="top"
      >
        <Container>
          <Navbar.Brand href="user">Ferest Rail</Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="/optiune1">Optiune 1</Nav.Link>
              <Nav.Link href="/optiune2">Optiune 2</Nav.Link>
              <NavDropdown title="Dropdown" id="collasible-nav-dropdown">
                <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                <NavDropdown.Item href="#action/3.2">
                  Another action
                </NavDropdown.Item>
                <NavDropdown.Item href="#action/3.3">
                  Something
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="#action/3.4">
                  Separated link
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
            <Nav>
              <Nav.Link href="#deets">More deets</Nav.Link>
              <Button variant="dark" onClick={logout}>
                Log Out
              </Button>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <div className="d-flex flex-column vh-100 align-items-center justify-content-center">
        <Button
          variant="dark"
          className="d-flex align-self-end"
          onClick={handleCreateButton}
        >
          Create
        </Button>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Nume Revizor</th>
              <th>Nume Operator</th>
              <th>Numar Tren</th>
              <th>Data Prestatiei</th>
              <th>Statia</th>
              <th>Tip Revizie</th>
              <th>Alte cheltuieli</th>
            </tr>
          </thead>
          <tbody>
            {!loadingRevisions &&
              revisions.map((item: any, index: number) => {
                return (
                  <tr>
                    <td>{index + 1}</td>
                    <td>{item?.revisor}</td>
                    <td>{item?.revision?.operatorName}</td>
                    <td>{item?.revision?.trainNumber}</td>
                    <td>
                      {new Date(item?.revision?.date).toLocaleDateString(
                        'en-GB'
                      )}
                    </td>
                    <td>{item?.revision?.station}</td>
                    <td>{item?.revision?.tip}</td>
                    <td>{item?.revision?.other}</td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
        {loadingRevisions && (
          <div className="d-flex vw-100 justify-content-center">
            <Spinner animation="border" />
          </div>
        )}
        <Pagination>
          <Pagination.First
            onClick={() => {
              setPage(0);
            }}
          />
          <Pagination.Prev onClick={setPreviousPage} />
          {new Array(totalPages).fill(0).map((_: any, index: number) => {
            if (index < totalPages)
              return (
                <Pagination.Item
                  onClick={() => {
                    setPage(index);
                  }}
                  active={currentPage == index}
                >
                  {index + 1}
                </Pagination.Item>
              );
          })}
          <Pagination.Next onClick={setNextPage} />
          <Pagination.Last
            onClick={() => {
              setPage(totalPages - 1);
            }}
          />
        </Pagination>
        <AddRevisionModal
          show={showAddRevisionsModal}
          onHide={onHideAddRevisionModal}
          startDate={startDate}
          addRevision={handleAddRevision}
          setOperator={setOperator}
          setTrainNumber={setTrainNumber}
          setRevisionType={setRevisionType}
          setStation={setStation}
          setStartDate={setStartDate}
          setOther={setOther}
          setUserCheck={setUserCheck}
        />
      </div>
    </>
  );
};

export default User;
