import React, { useState, useEffect, useCallback } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";

import {
  auth,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
} from "../../firebase";
import "./LogIn.scss";
import "./LogIn.css";
import Loading from "../loading/Loading";

const LogIn = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [user, loading, error] = useAuthState(auth);

  const navigate = useNavigate();

  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      return;
    }

    if (user) {
      navigate("/user");
    }
  }, [user, loading]);

  const handleRegister = useCallback(() => {
    if (email === "" || password === "") console.log("esti prost");
    else registerWithEmailAndPassword(email, email + "@test.com", password);
  }, [email, password]);

  if (loading) return <Loading />;

  return (
    <div className="login-container vh-100">
      <Form>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Email address</Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter email"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setEmail(e.currentTarget.value);
            }}
          />
          <Form.Text className="text-muted">
            We'll never share your email with anyone else.
          </Form.Text>
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Password"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setPassword(e.currentTarget.value);
            }}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicCheckbox">
          <Form.Check type="checkbox" label="Check me out" />
        </Form.Group>
        <Button
          variant="primary"
          onClick={() => {
            logInWithEmailAndPassword(email + "@test.com", password);
          }}
        >
          Log In
        </Button>
        <Button className="ms-3" variant="primary" onClick={handleRegister}>
          Sign Up
        </Button>
      </Form>
    </div>
  );
};

export default LogIn;
