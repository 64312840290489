import React, { useState, forwardRef, useCallback } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import DatePicker, { registerLocale } from 'react-datepicker';
import ro from 'date-fns/locale/ro';

import './AddRevisionModal.css';
import 'react-datepicker/dist/react-datepicker.css';

const AddRevisionModal = (props: {
  onHide: () => void;
  show: boolean;
  startDate: any;
  setOperator: React.Dispatch<any>;
  setTrainNumber: React.Dispatch<any>;
  setStartDate: React.Dispatch<any>;
  setStation: React.Dispatch<any>;
  setRevisionType: React.Dispatch<any>;
  setOther: React.Dispatch<any>;
  setUserCheck: React.Dispatch<any>;
  addRevision: () => void;
}) => {
  const fields = [
    'Nume Operator',
    'Numar Tren',
    'Data Prestatiei',
    'Statia',
    'Tip Revizie',
    'Alte Cheltuieli',
  ];

  const revisionsTypes = [
    'RTS',
    'RTC',
    'RTT',
    'PC',
    'PVp',
    'RTS+PC',
    'RTC+PC',
    'RTT+PVp',
  ];

  const setters = [
    props.setOperator,
    props.setTrainNumber,
    props.setStartDate,
    props.setStation,
    props.setRevisionType,
    props.setOther,
  ];

  const CustomInput = forwardRef(
    ({ value, onClick }: { value: any; onClick: any }, ref: any) => (
      <button
        className="form-control d-flex justify-content-start"
        onClick={onClick}
        ref={ref}
      >
        {value}
      </button>
    )
  );

  registerLocale('ro', ro);

  return (
    <Modal show={props.show} onHide={props.onHide} centered scrollable>
      <Modal.Body>
        {fields.map((item, index) => {
          if (item === 'Data Prestatiei') {
            return (
              <Form.Group key={index} className="mb-3">
                <Form.Label>{item}</Form.Label>
                <DatePicker
                  selected={props.startDate}
                  onChange={(date: Date) => props.setStartDate(date)}
                  customInput={
                    <CustomInput value={undefined} onClick={undefined} />
                  }
                  locale="ro"
                  dateFormat="dd/MM/yyyy"
                />
              </Form.Group>
            );
          } else if (item === 'Tip Revizie')
            return (
              <Form.Group key={index} className="mb-3">
                <Form.Label>{item}</Form.Label>
                <Form.Select
                  onChange={(e) => setters[index](e.currentTarget.value)}
                >
                  {revisionsTypes.map((revision) => (
                    <option>{revision}</option>
                  ))}
                </Form.Select>
              </Form.Group>
            );
          else
            return (
              <Form.Group key={index} className="mb-3">
                <Form.Label>{item}</Form.Label>
                <Form.Control
                  placeholder={item}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setters[index](e.currentTarget.value)
                  }
                />
              </Form.Group>
            );
        })}
        <Form.Group className="mb-3">
          <Form.Check
            onClick={() => {
              props.setUserCheck((prevState: boolean) => !prevState);
            }}
            type="checkbox"
            label="Datele sunt corecte"
          />
        </Form.Group>
        <Button variant="primary" onClick={props.addRevision}>
          Add revision
        </Button>
      </Modal.Body>
    </Modal>
  );
};

export default AddRevisionModal;
